document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementById('contactForm') as HTMLFormElement;
  
    form?.addEventListener('submit', async (event) => {
      event.preventDefault();
  
      const formData = new FormData(form);
      const formProps = Object.fromEntries(formData.entries());
  
      try {
        const response = await fetch('/api/sendEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formProps),
        });
  
        if (!response.ok) {
          throw new Error(`Failed to send message: ${response.statusText}`);
        }
  
        const data = await response.json();
        // console.log('Success:', data);
        alert('Message sent successfully!');
        form.reset();
      } catch (error) {
        // console.error('Error:', error instanceof Error ? error.message : error);
        alert('There was an error sending your message.');
      }
    });
  });
  