const letters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

let intervals: Map<HTMLElement, number> = new Map();

const textElements = document.querySelectorAll('[data-value]');

textElements.forEach((h1Element) => {
  const targetElement = h1Element as HTMLElement;
  const originalText = targetElement.innerText;

  const animateText = (targetElement: HTMLElement, targetText: string, forward: boolean) => {
    let iteration: number = forward ? 0 : targetText.length;
    
    // Clear any existing interval for this element
    const existingInterval = intervals.get(targetElement);
    if (existingInterval !== undefined) {
      clearInterval(existingInterval);
      intervals.delete(targetElement);
    }
    
    const interval = window.setInterval(() => {
      targetElement.innerText = targetText
        .split("")
        .map((_letter: string, index: number) => {
          if (forward ? (index < iteration) : (index >= iteration)) {
            return targetText[index];
          }
        
          return letters[Math.floor(Math.random() * 26)];
        })
        .join("");
      
      if (forward ? (iteration >= targetText.length) : (iteration <= 0)) {
        clearInterval(interval);
        intervals.delete(targetElement);
      }
      
      iteration += forward ? (1 / 3) : (-1 / 3);
    }, 10);

    intervals.set(targetElement, interval);
  };

  // Mouseover event to start the text animation
  h1Element.addEventListener("mouseover", (event: Event) => {
    const targetElement = event.target as HTMLElement;
    const targetText = targetElement.dataset.value as string || originalText; // Fallback to originalText if data-value is not set
    animateText(targetElement, targetText, true);
  });

  // Mouseout event to revert the text back to the original with animation
  h1Element.addEventListener("mouseout", (event: Event) => {
    const targetElement = event.target as HTMLElement;
    animateText(targetElement, originalText, false);
  });
});
