import * as THREE from 'three'
import Sizes from "./Utils/Sizes";
import Time from './Utils/Time';
import Resources from './Utils/Resources';
import Debug from './Utils/Debug';
import Camera from './Camera';
import Renderer from './Renderer';
import World from './World/World';
import sources from './sources';
// import Stats from 'stats.js';

let instance: any = null;

export default class Experience 
{
    private _canvas: HTMLCanvasElement | undefined;
    private _debug?: Debug;
    private _sizes?: Sizes;
    private _time?: Time;
    private _scene?: THREE.Scene;
    private _resources?: Resources;
    private _camera?: Camera;
    private _renderer?: Renderer;
    private _world?: World;
    private _stats?: Stats;

    constructor(canvas?: HTMLCanvasElement) {
        // Singleton
        if (instance) {
            return instance;
        }
        instance = this;

        // Global access
        window.experience = this;

        // Options
        this._canvas = canvas;

        // Setup
        this._debug = new Debug();
        this._sizes = new Sizes();
        this._time = new Time();
        this._scene = new THREE.Scene();
        this._resources = new Resources(sources);
        this._camera = new Camera();
        this._renderer = new Renderer();
        this._world = new World();
        // this._stats = new Stats();



        // this._stats.showPanel(0);
        // document.body.appendChild(this._stats.dom);



        // Sizes Resize Event
        this._sizes.on('resize', () => {
            this.resize();
        });

        // Time Tick Event
        this._time.on('tick', () => {
            this.update();
        });
    }

    private resize(): void {
        if (this._world) {
            // this._world.resize();
        }
        if (this._camera) {
            this._camera.resize();
        }
        if (this._renderer) {
            this._renderer.resize();
        }
    }

    private update(): void {
        this._stats?.begin();
        if (this._camera) {
            // this._camera.update();
        }
        if (this._world) {
            this._world.update();
        }
        if (this._renderer) {
            this._renderer.update();
        }
        this._stats?.end();
    }

    public get debug(): Debug | undefined{
        return this._debug;
    }

    public get sizes(): Sizes | undefined {
        return this._sizes;
    }

    public get time(): Time | undefined{
        return this._time;
    }

    public get scene(): THREE.Scene | undefined{ 
        return this._scene;
    }

    public get canvas(): HTMLCanvasElement | undefined {
        return this._canvas;
    }

    public get instance(): any{
        return this.instance;
    }

    public get camera(): Camera | undefined{
        return this._camera;
    }

    public get resources(): Resources | undefined{
        return this._resources;
    }
}