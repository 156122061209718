// interface CustomHTMLElement extends HTMLElement {
//     animation?: Animation;
//   }

//   document.addEventListener('DOMContentLoaded', (): void => {
//     const track: CustomHTMLElement | null = document.getElementById("image-track") as CustomHTMLElement | null;
  

// const getNumericValue = (value: string | undefined): number => {
//     if (value === undefined) return 0;
//     const number = parseFloat(value);
//     return isNaN(number) ? 0 : number;
// };

// const handleMouseDown = (clientX: number): void => {
//     if (track) {
//         track.dataset.mouseDownAt = clientX.toString();
//     }
// };

// const handleMouseUp = (): void => {
//     if (track) {
//         track.dataset.mouseDownAt = "0";
//         track.dataset.prevPercentage = track.dataset.percentage;
//         if (track.animation) {
//             track.animation.cancel();
//           }
//     }
// };

// const handleMouseMove = (clientX: number): void => {
//     if (track && track.dataset.mouseDownAt !== "0") {
//         const mouseDelta = getNumericValue(track.dataset.mouseDownAt) - clientX ;
//         const maxDelta = window.innerWidth / 2;

//         const percentage = (mouseDelta / maxDelta) * -100;
//         const nextPercentageUnconstrained = getNumericValue(track.dataset.prevPercentage) + percentage;
//         const nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -82);

//         track.dataset.percentage = nextPercentage.toString();

//         // Animation for track
//         track.animation = track.animate({
//             transform: `translate(${nextPercentage}%, -50%)`
//         }, { duration: 3400, fill: 'forwards' });

//     }
// };
// // Event Listeners for Mouse
// window.addEventListener('mousedown', (e: MouseEvent) => handleMouseDown(e.clientX));
// window.addEventListener('mouseup', () => handleMouseUp());
// window.addEventListener('mousemove', (e: MouseEvent) => handleMouseMove(e.clientX));

// // Event Listeners for Touch
// window.addEventListener('touchstart', (e: TouchEvent) => handleMouseDown(e.touches[0].clientX));
// window.addEventListener('touchend', () => handleMouseUp());
// window.addEventListener('touchmove', (e: TouchEvent) => handleMouseMove(e.touches[0].clientX));
//   });
// Default theme
import '@splidejs/splide/css';


// or other themes
import '@splidejs/splide/css/skyblue';
import '@splidejs/splide/css/sea-green';


// or only core styles
import '@splidejs/splide/css/core';
  
import Splide from '@splidejs/splide';

new Splide( '.splide' ,{
    type   : 'loop',
    perPage: 1,
  }).mount();


  