import "../styles.css";
import Experience from "./Experience/Experience";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const experience = new Experience(
  document.querySelector("canvas.webgl") as HTMLCanvasElement
);

document.addEventListener("DOMContentLoaded", function () {
  const greetings: string[] = ["Hi!", "Hello!", "Hola!", "Salut!", "Ahoj!", "Ciao!"];
  let currentGreetingIndex: number = 0;

  const greetingElement = document.querySelector(
    ".dynamic-greeting"
  ) as HTMLElement | null;

  if (greetingElement) {
    setInterval(() => {
      currentGreetingIndex = (currentGreetingIndex + 1) % greetings.length;
      greetingElement.textContent = greetings[currentGreetingIndex];
    }, 2000);
  }
});


const cardsElement = document.getElementById("aspect-cards-container") as HTMLElement; 

if (cardsElement) {
    cardsElement.onmousemove = (e: MouseEvent) => {
        const cards = document.getElementsByClassName("aspect-card");
        
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i] as HTMLElement;
            const rect = card.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            card.style.setProperty("--mouse-x", `${x}px`);
            card.style.setProperty("--mouse-y", `${y}px`);
        }
    };
}

const companyCardsElement = document.getElementById("companies-grid") as HTMLElement; 

if (companyCardsElement) {
    companyCardsElement.onmousemove = (e: MouseEvent) => {
        const cards = document.getElementsByClassName("company-card");
        
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i] as HTMLElement;
            const rect = card.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            card.style.setProperty("--mouse-x", `${x}px`);
            card.style.setProperty("--mouse-y", `${y}px`);
        }
    };
}


const linkedinDiv: HTMLElement | null = document.getElementById('linkedinLink');

if (linkedinDiv) {
    linkedinDiv.addEventListener('click', function() {
        window.open('https://www.linkedin.com/in/sechila-costin-750438157/');
    });
}

const githubDiv: HTMLElement | null = document.getElementById('githubLink');

if (githubDiv) {
    githubDiv.addEventListener('click', function() {
        window.open('https://github.com/cscoder97');
    });
}


const instaDiv: HTMLElement | null = document.getElementById('instaLink');

if (instaDiv) {
    instaDiv.addEventListener('click', function() {
        window.open('https://www.linkedin.com/in/sechila-costin-750438157/');
    });
}



// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

// Define the type for the elements array
const textElements: Element[] = gsap.utils.toArray('.text');

textElements.forEach((text: Element) => {
  gsap.to(text, {
    backgroundSize: '100%',
    ease: 'none',
    scrollTrigger: {
      trigger: text,
      start: 'center 80%',
      end: 'center 20%',
      scrub: true,
    },
  });
});


document.addEventListener('DOMContentLoaded', (): void => {
    const hamburger: HTMLElement | null = document.querySelector('.hamburger-menu');
    const infoContainer: HTMLElement | null = document.querySelector('.info-container');
  
    const closeMenu = (): void => {
      if (infoContainer) {
        infoContainer.classList.remove('active');
      }
    };
  
    if (hamburger && infoContainer) {
      hamburger.addEventListener('click', (): void => {
        infoContainer.classList.toggle('active');
      });
  
      // Add click event listeners to all options inside the infoContainer
      const options: NodeListOf<HTMLElement> = infoContainer.querySelectorAll('.header-button');
      options.forEach((option: HTMLElement): void => {
        option.addEventListener('click', closeMenu);
      });
    }
  });