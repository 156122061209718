document.addEventListener('DOMContentLoaded', () => {
    const elementsWithTooltip = document.querySelectorAll('[data-tooltip]');
  
    elementsWithTooltip.forEach(element => {
      const tooltipTextContainer = document.createElement('div');
      tooltipTextContainer.className = 'tooltip-text-container';
      const tooltipText = document.createElement('div');
        tooltipText.className = 'tooltip-text';
      tooltipText.textContent = element.getAttribute('data-tooltip') as string;
        tooltipTextContainer.appendChild(tooltipText);

      element.appendChild(tooltipTextContainer);
  
      element.addEventListener('mouseover', () => {
        tooltipText.style.visibility = 'visible';
        tooltipText.style.opacity = '1';
      });
  
      element.addEventListener('mouseout', () => {
        tooltipText.style.visibility = 'hidden';
        tooltipText.style.opacity = '0';
      });
    });
  });
  