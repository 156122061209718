import * as THREE from 'three';
import EventEmitter from "./EventEmitter";
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

type LoadedResource = THREE.Group | THREE.Texture | THREE.CubeTexture | GLTF;

interface ILoaders {
    gltfLoader: GLTFLoader;
    dracoLoader: DRACOLoader;
    // textureLoader: THREE.TextureLoader;
    // cubeTextureLoader: THREE.CubeTextureLoader;
}

interface IResourceDefinition {
    name: string;
    type: 'gltfModel';
    path: string | string[];
}

interface IResourceMap {
    [key: string]: LoadedResource;
}

export default class Resources extends EventEmitter {
    private _items: IResourceMap = {};
    private _toLoad: number;
    private _loaded: number;
    private _sources: IResourceDefinition[];
    private _loaders: ILoaders | undefined;

    constructor(sources: IResourceDefinition[]) {
        super();

        this._sources = sources;
        this._toLoad = this._sources.length;
        this._loaded = 0;

        this._setLoaders();
        this._startLoading();
    }

    private _setLoaders(): void {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('draco/');
        dracoLoader.setDecoderConfig({ type: 'ts' });

        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader);

        this._loaders = {
            gltfLoader,
            dracoLoader,
            // textureLoader: new THREE.TextureLoader(),
            // cubeTextureLoader: new THREE.CubeTextureLoader(),
        };
    }

    private _startLoading(): void {
        this._sources.forEach(source => {
            // switch (source.type) {
            //     case 'gltfModel':
                    if (this._loaders) {
                        this._loaders.gltfLoader.load(
                            source.path as string,
                            file => this._sourceLoaded(source, file)
                        );
                    }
                    // break;
                // case 'cubeTexture':
                //     if (this._loaders) {
                //         this._loaders.cubeTextureLoader.load(
                //             source.path as string[],
                //             file => this._sourceLoaded(source, file)
                //         );
                //     }
                //     break;
                // case 'texture':
                //     if (this._loaders) {
                //         this._loaders.textureLoader.load(
                //             source.path as string,
                //             file => this._sourceLoaded(source, file)
                //         );
                //     }
                //     break;
            // }
        });
    }

    private _sourceLoaded(source: IResourceDefinition, file: LoadedResource): void {
        this._items[source.name] = file;
        this._loaded++;

        if (this._loaded === this._toLoad) {
            console.log('Finished loading');
            this.trigger('loaded');
        }
    }

    public get items(): IResourceMap {
        return this._items;
    }
}






