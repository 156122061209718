interface IResourceDefinition {
    name: string;
    type: 'gltfModel'; // Add more types if needed
    path: string | string[];
}

const resources: IResourceDefinition[] = [
    // {
    //     name: 'environmentMapTexture',
    //     type: 'cubeTexture',
    //     path: [
    //         './static/textures/environmentMap/px.jpg',
    //         './static/textures/environmentMap/nx.jpg',
    //         './static/textures/environmentMap/py.jpg',
    //         './static/textures/environmentMap/ny.jpg',
    //         './static/textures/environmentMap/pz.jpg',
    //         './static/textures/environmentMap/nz.jpg'
    //     ]
    // },
    // {
    //     name: 'iphoneModel',
    //     type: 'gltfModel',
    //     path: 'models/iphone_lowpoly/scene.gltf'
    //     // /Users/costinsechila/code/BucAms/PersonalWebSite/threejscv/static/models/iphone/glTF/scene.gltf
    // },
    // {
    //     name: 'macbookModel',
    //     type: 'gltfModel',
    //     path: 'models/MacBook/glTF/scene.gltf'
    // },
    // {
    //     name: 'ipadModel',
    //     type: 'gltfModel',
    //     path: 'models/Ipad/glTF/scene.gltf'
    // }
];

export default resources;
