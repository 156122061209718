import * as dat from 'lil-gui';


export default class Debug {
    private _active: boolean;
    private _ui: any;

    constructor() {
        this._active = window.location.hash === '#debug';

        if (this._active) {
            this._ui = new dat.GUI();
        }
    }

    public get active(): boolean {
        return this._active;
    }

    public get ui(): any {
        return this._ui;
    }
}