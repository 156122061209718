import * as THREE from 'three';
import Experience from './Experience';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Sizes from './Utils/Sizes';

export default class Camera 
{
    private _experience: Experience;
    private _sizes: Sizes;
    private _scene: THREE.Scene;
    private readonly _canvas: HTMLCanvasElement | undefined;
    private _instance?: THREE.PerspectiveCamera | undefined;
    // private _controls: OrbitControls | undefined;

    constructor() {
        this._experience = new Experience();
        this._sizes = this._experience.sizes || new Sizes();
        this._scene = this._experience.scene || new THREE.Scene();
        this._canvas = this._experience.canvas;

        this._setInstance();

        // this._setOrbitControls();
    }

    private _setInstance(): void {
        this._instance = new THREE.PerspectiveCamera(
            70,
            this._sizes.width / this._sizes.height,
            0.001,
            800
        );

        this._instance.position.set(0, 0, 1.5);
        this._scene.add(this._instance);
    }

    // private _setOrbitControls(): void {
    //     if (this._instance) {
    //         this._controls = new OrbitControls(this._instance, this._canvas);
    //         this._controls.enableDamping = true;
    //     }
    // }
    public resize(): void {
        if (this._instance) {
            this._instance.aspect = this._sizes.width / this._sizes.height;
            this._instance.updateProjectionMatrix();
        }
    }

    public get instance(): THREE.PerspectiveCamera | undefined{
        return this._instance;
    }


    // public get controls(): OrbitControls | undefined {
    //     return this._controls;
    // }
}