import * as THREE from 'three';
import Experience from "../Experience";
import Resources from "../Utils/Resources";
import Time from "../Utils/Time";
import Sizes from "../Utils/Sizes";
import lavalampGradientVertexShader from '/@/shaders/background/lavalampGradientVertex.glsl'
import lavalampGradientFragmentShader from '/@/shaders/background/lavalampGradientFragment.glsl'

// import colors from 'nice-color-palettes/1000.json';

const colors = [
['#53ac59', '#3b8952', '#0f684b', '#03484c', '#1c232e'], 
// ['#d6d578', '#b1bf63', '#9dad42', '#258a60', '#0a3740'],
['#1c31a5', '#101f78', '#020f59', '#010937', '#000524'],
['#07f9a2', '#09c184', '#0a8967', '#0c5149', '#0d192b'],
// ['#4e031e', '#5d2d4e', '#5a4c6e', '#447390', '#05a1ad'],
['#40ffdc', '#00a9d4', '#1c3166', '#240047', '#1c0021'],
]


export default class Background {
    private _experience: Experience;
    private _scene: THREE.Scene | undefined;
    private _resources: Resources | undefined;
    private _time: Time | undefined;
    private _sizes: Sizes | undefined;

    private _geometry?: THREE.PlaneGeometry;
    private _material?: THREE.ShaderMaterial;
    private _mesh?: THREE.Mesh;
    private arr: Array<string>;
    private pallete: any = 
    // colors[3]
    colors[Math.floor(Math.random() * colors.length)];

    constructor() {
        // console.log("BG")
        this._experience = new Experience();
        this._scene = this._experience.scene;
        this._resources = this._experience.resources;
        this._time = this._experience.time;
        this._sizes = this._experience.sizes;
        this.arr = [];
        
        this.pallete = this.pallete.map((color: THREE.ColorRepresentation | undefined) => {
            if (color) {
                this.arr.push(color as string);
            }
            return new THREE.Color(color as string);
        });

        this._setGeometry();
        this._setMaterial();
        this._setMesh();
    }


    private _setGeometry() {
        const width = window.innerWidth; 
        if (width < 650) {
            this._geometry = new THREE.PlaneGeometry(1.5, 2, 100, 100);
            this._geometry.scale(2,2,1);
        } else {
            this._geometry = new THREE.PlaneGeometry(4, 2.5, 256, 256);
            this._geometry.scale(2,2,1);
        }
    }

    private _setMaterial() {
        this._material = new THREE.ShaderMaterial({
            precision: 'lowp',
            vertexShader: lavalampGradientVertexShader,
            fragmentShader: lavalampGradientFragmentShader,
            uniforms:
            {
                uTime: { value: 0 },
                uColor: { value: this.pallete },
                resolution: { value: new THREE.Vector4() },
            },
            // wireframe: true,
        })
    }

    private _setMesh() {
        this._mesh = new THREE.Mesh(this._geometry, this._material);
        this._mesh.position.y = 0;
        if (this._scene) {
            this._scene.add(this._mesh);
        }
    }

    public update() {
        if (this._material && this._time) {
            this._material.uniforms.uTime.value = this._time.elapsedTime * 0.00002;
        }
    }

    public dispose() {
        // Remove the _mesh object from the scene
        if (this._mesh && this._scene) {
            this._scene.remove(this._mesh);
        }
        // Dispose of the geometry and material
        if (this._geometry) {
            this._geometry.dispose();
        }
        if (this._material) {
            this._material.dispose();
        }
    }
}