import Experience from "../Experience";
import Environment from './Environment';
import Resources from '../Utils/Resources';
// import Iphone from './Iphone';
// import Macbook from './Macbook';
// import Ipad from './Ipad';
import Background from './Background';

export default class World 
{
    private _experience: Experience;
    private _resources: Resources | undefined;
    // private _iphone: any = null;
    // private _macbook: any = null;
    // private _ipad: any = null;
    private _background: any = null;
    // private _environment: Environment | undefined;

    constructor() {
        this._experience = new Experience();
        this._resources = this._experience.resources;
        // console.log("BG")
        this._background = new Background();

        // Wait for resources
        // this._resources?.on('loaded', () => {
        //     console.log("BG")

        //     // Setup
        //     this._background = new Background();
        //     // this._environment = new Environment();
        //     // this._iphone = new Iphone();
        //     // this._ipad = new Ipad();
        //     // this._macbook = new Macbook();
        // })

        this.startContinuousUpdate();
    };


    private startContinuousUpdate(): void {
        const update = (): void => {
            requestAnimationFrame(update);
            // this.handleScrollEffect();
        };
        update();
    }

    private lerp(start: number, end: number, alpha: number): number {
        return start * (1 - alpha) + end * alpha;
    }

    // private handleScrollEffect(): void {
    //     const scrollPercentage = window.scrollY / document.documentElement.scrollHeight;
    //     const width = window.innerWidth;
        
    //     if (this._iphone && this._ipad && width > 2000) {
    //         if (scrollPercentage <= 0.069) {
    //             this._iphone.model.position.y = 0.05;
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.22, 0.05);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, Math.PI/3, 0.05);
    //         }

    //         if (scrollPercentage > 0.069 && scrollPercentage <= 0.31) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 1.5, 0.1);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, -Math.PI/2, 0.05);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -1.5, 0.2);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, -Math.PI / 2, 0.01);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -1.5, 0.05);
    //         } 

    //         if (scrollPercentage > 0.31 && scrollPercentage <= 0.4) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.35, 0.05);
    //             this._iphone.model.position.y = -0.1;
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, Math.PI/1.5, 0.005);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -0.38, 0.05);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, Math.PI / 3, 0.005);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -0.14, 0.008);
    //         }

    //         if (scrollPercentage > 0.4) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 1.5, 0.05);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, -Math.PI/2, 0.01);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -1.5, 0.05);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, -Math.PI, 0.01);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -1.5, 0.05);
    //         }
    //     }

    //     if (this._iphone && this._ipad && width > 1280 && width < 2000) {
    //         if (scrollPercentage <= 0.032) {
    //             this._iphone.model.position.y = -0.04;
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.22, 0.05);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, Math.PI/1.5, 0.05);
    //         }

    //         if (scrollPercentage > 0.032 && scrollPercentage <= 0.278) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.8, 0.1);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, -Math.PI/2, 0.05);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -0.85, 0.2);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, -Math.PI / 2, 0.01);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -1.5, 0.05);
    //         } 

    //         if (scrollPercentage > 0.278 && scrollPercentage <= 0.35) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.35, 0.05);
    //             this._iphone.model.position.y = -0.1;
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, Math.PI/1.5, 0.005);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -0.35, 0.05);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, Math.PI / 3, 0.005);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -0.14, 0.008);
    //         }

    //         if (scrollPercentage > 0.35) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.8, 0.05);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, -Math.PI/2, 0.01);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -0.85, 0.05);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, -Math.PI, 0.01);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -1.5, 0.05);
    //         }
    //     }

    //     if (this._iphone && this._ipad && width > 1024 && width < 1280) {
    //         if (scrollPercentage <= 0.039) {
    //             this._iphone.model.position.y = -0.2;
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.1, 0.05);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, Math.PI/1.5, 0.05);
    //         }

    //         if (scrollPercentage > 0.039 && scrollPercentage <= 0.252) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.8, 0.1);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, -Math.PI/2, 0.05);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -0.85, 0.2);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, -Math.PI / 2, 0.01);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -1.5, 0.05);
    //         } 

    //         if (scrollPercentage > 0.252 && scrollPercentage <= 0.33) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.28, 0.05);
    //             this._iphone.model.position.y = -0.1;
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, Math.PI/1.5, 0.005);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -0.28, 0.05);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, Math.PI / 3, 0.005);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -0.14, 0.008);
    //         }

    //         if (scrollPercentage > 0.33) {
    //             this._iphone.model.position.x = this.lerp(this._iphone.model.position.x, 0.8, 0.05);
    //             this._iphone.model.rotation.y = this.lerp(this._iphone.model.rotation.y, -Math.PI/2, 0.01);

    //             this._ipad.model.position.x = this.lerp(this._ipad.model.position.x, -0.85, 0.05);
    //             this._ipad.model.rotation.y = this.lerp(this._ipad.model.rotation.y, -Math.PI, 0.01);

    //             this._macbook.model.position.y = this.lerp(this._macbook.model.position.y, -1.5, 0.05);
    //         }
    //     }
    // }

  
    public update() {
        if (this._background) {
            this._background.update();
        }
    }

    // public resize() {
    //     if (this._macbook) {
    //         this._macbook.resizeModel(); // Adjust the scale of the MacBook model
    //     }
    //     if (this._ipad) {
    //         this._ipad.resizeModel(); // Adjust the scale of the MacBook model
    //     }
    //     if (this._iphone) {
    //         this._iphone.resizeModel(); // Adjust the scale of the MacBook model
    //     }
    // }

    // public set environment(env: Environment) {
    //     this._environment = env;
    // }

    // public createIphone() {
    //     if (this._iphone === null) {
    //         this._iphone = new Iphone();
    //         // Add to scene if necessary
    //     }
    // }

    // public destroyIphone() {
    //     if (this._iphone !== null) {
    //         // Remove from scene and deallocate any resources
    //         this._iphone.dispose();
    //         this._iphone = null;
    //     }
    // }

    // public createIpad() {
    //     if (this._ipad === null) {
    //         this._ipad = new Ipad();
    //         // Add to scene if necessary
    //     }
    // }

    // public destroyIpad() {
    //     if (this._ipad !== null) {
    //         // Remove from scene and deallocate any resources
    //         this._ipad.dispose();
    //         this._ipad = null;
    //     }
    // }
    // public createMacbook() {
    //     if (this._macbook === null) {
    //         this._macbook = new Macbook();
    //         // Add to scene if necessary
    //     }
    // }

    // public destroyMacbook() {
    //     if (this._macbook !== null) {
    //         // Remove from scene and deallocate any resources
    //         this._macbook.dispose();
    //         this._macbook = null;
    //     }
    // }
}