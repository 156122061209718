import gsap from 'gsap';

// Get cursor element and enforce HTMLElement type assumption
const cursor = document.querySelector<HTMLElement>('.cursor');
const cursorScale = document.querySelectorAll<HTMLElement>('.cursor-scale');
let mouseX = 0;
let mouseY = 0;

// Animation with GSAP for the cursor
gsap.to({}, 0.016, {
    repeat: -1,
    onRepeat: () => {
        if (cursor) {
            gsap.set(cursor, {
                css: {
                    left: mouseX,
                    top: mouseY
                }
            });
        }
    }
});

// Function to update mouseX and mouseY based on the mouse position
const updateMousePosition = (e: MouseEvent): void => {
    mouseX = e.clientX;
    mouseY = e.clientY;
};

// Function to update mouseX and mouseY based on the touch position
const updateTouchPosition = (e: TouchEvent): void => {
    if (e.touches.length > 0) {
        mouseX = e.touches[0].clientX;
        mouseY = e.touches[0].clientY;
    }
};

// Listen to mouse movement
window.addEventListener("mousemove", updateMousePosition);

// Listen to touch movement
window.addEventListener("touchmove", updateTouchPosition);

// Apply or remove CSS classes on mouse/touch events
cursorScale.forEach((link: HTMLElement) => {
    link.addEventListener('mouseleave', () => {
        cursor?.classList.remove('grow');
        cursor?.classList.remove('grow-small');
    });

    link.addEventListener('mousemove', () => {
        cursor?.classList.add('grow');
        if (link.classList.contains('small')) {
            cursor?.classList.remove('grow');
            cursor?.classList.add('grow-small');
        }
    });

    // Handle touch start and end events
    link.addEventListener('touchstart', () => {
        cursor?.classList.add('grow');
        if (link.classList.contains('small')) {
            cursor?.classList.remove('grow');
            cursor?.classList.add('grow-small');
        }
    }, {passive: true});
    
    link.addEventListener('touchend', () => {
        cursor?.classList.remove('grow');
        cursor?.classList.remove('grow-small');
    }, {passive: true});
});
