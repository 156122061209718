import EventEmitter from './EventEmitter.js';

export default class Time extends EventEmitter {
    [x: string]: any;
    private _currentTime: number;
    private _elapsedTime: number;
    public _deltaTime: number;
    private _startTime: number;

    constructor() {
        super();

        // Setup
        this._startTime = Date.now();
        this._currentTime = this._startTime;
        this._elapsedTime = 0;
        this._deltaTime = 16;

        window.requestAnimationFrame(() => {
            this.tick();
        });
    }

    public tick(): void {
        const currentTime = Date.now();
        this._deltaTime = currentTime - this._currentTime;
        this._currentTime = currentTime;
        this._elapsedTime = this._currentTime - this._startTime;

        this.trigger('tick');
        
        window.requestAnimationFrame(() => {
            this.tick();
        });
    }


    public get currentTime(): number {
        return this._currentTime;
    }

    public get elapsedTime(): number {
        return this._elapsedTime;
    }

    public get deltaTime(): number { 
        return this._deltaTime;
    }
}