import EventEmitter from './EventEmitter.js';

export default class Sizes extends EventEmitter
{
    [x: string]: any;
    private _width: number;
    private _height: number;
    private _pixelRatio: number;

    constructor() {
        super();

        // Setup
        this._width = window.innerWidth;
        this._height = window.innerHeight;
        this._pixelRatio = Math.min(window.devicePixelRatio, 2);

        // Resize event
        window.addEventListener('resize', () => {
            this._width = window.innerWidth;
            this._height = window.innerHeight;
            this._pixelRatio = Math.min(window.devicePixelRatio, 2);

            this.trigger('resize');
        });
    }

    public get width(): number {
        return this._width;
    }

    public get height(): number {
        return this._height;
    }

    public get pixelRatio(): number {
        return this._pixelRatio;
    }
}