import * as THREE from 'three';
import Camera from "./Camera";
import Experience from "./Experience";
import Sizes from "./Utils/Sizes";

export default class Renderer {
    private _experience: Experience;
    private _canvas: HTMLCanvasElement | undefined;
    private _sizes: Sizes | undefined;
    private _scene: THREE.Scene | undefined;
    private _camera: Camera | undefined;
    private _instance?: THREE.WebGLRenderer;

    constructor() {
        this._experience = new Experience();
        this._canvas = this._experience.canvas;
        this._sizes = this._experience.sizes;
        this._scene = this._experience.scene;
        this._camera = this._experience.camera;

        this._setInstance();


    }

    private _setInstance(): void {
        this._instance = new THREE.WebGLRenderer({
            canvas: this._canvas,
            powerPreference: 'high-performance',
            antialias: true,
            alpha: false,
        });

        this._instance.useLegacyLights = false;
        this._instance.toneMapping = THREE.CineonToneMapping;
        this._instance.toneMappingExposure = 1.75;
        this._instance.shadowMap.enabled = false;
        this._instance.shadowMap.autoUpdate = false
        // this._instance.shadowMap.type = THREE.PCFSoftShadowMap;
        this._instance.setClearColor('#1E1E1E');
        
        if (this._sizes) {
            this._instance.setSize(this._sizes.width, this._sizes.height);
            this._instance.setPixelRatio(this._sizes.pixelRatio);
        }
    }

    public resize(): void {
        if (this._instance) {
            this._instance.setSize(this._sizes?.width ?? 0, this._sizes?.height ?? 0);
            this._instance.setPixelRatio(this._sizes?.pixelRatio ?? 1);
        }
    }

    public update(): void {
        if (this._instance && this._scene && this._camera) {
            this._instance.render(this._scene, this._camera.instance as THREE.Camera);
            // console.log(this._instance?.info);

        }
    }

    public get instance(): THREE.WebGLRenderer | undefined {
        return this._instance;
    }
}